import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"

const PrivacyPage = ({ t: trans, intl }) => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>{t({ intl: trans, id: "headerFAQ" })}</h1>
          <p>{t({ intl: trans, id: "textComingSoon" })}</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default withTrans(PrivacyPage)
